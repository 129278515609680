import { jsx as u } from "react/jsx-runtime";
import { useStateTogether as m } from "@multisynq/react-together";
import { Select as a } from "antd";
function f({
  rtKey: e,
  ...t
}) {
  const [o, r] = m(e, []);
  return /* @__PURE__ */ u(
    a,
    {
      mode: "multiple",
      ...t,
      value: o || [],
      onChange: (l) => r(l)
    }
  );
}
export {
  f as default
};
