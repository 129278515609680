import { jsx as n } from "react/jsx-runtime";
import { useStateTogether as u } from "@multisynq/react-together";
import { Rating as i } from "primereact/rating";
function g({
  rtKey: e,
  ...t
}) {
  const [o, r] = u(e, null);
  return /* @__PURE__ */ n(
    i,
    {
      ...t,
      value: o || void 0,
      onChange: (a) => r(a.value)
    }
  );
}
export {
  g as default
};
