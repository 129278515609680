import { jsx as n } from "react/jsx-runtime";
import { useStateTogether as h } from "@multisynq/react-together";
import { InputSwitch as u } from "primereact/inputswitch";
function m({
  rtKey: e,
  ...t
}) {
  const [o, r] = h(e, !1);
  return /* @__PURE__ */ n(
    u,
    {
      ...t,
      checked: o,
      onChange: (c) => r(c.value)
    }
  );
}
export {
  m as default
};
