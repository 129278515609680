import { jsx as m } from "react/jsx-runtime";
import { useStateTogether as n } from "@multisynq/react-together";
import { Slider as f } from "antd";
function d({
  rtKey: e,
  value: r,
  defaultValue: t,
  ...o
}) {
  const [a, i] = n(
    e,
    r || t || 0
  );
  return /* @__PURE__ */ m(f, { ...o, value: a, onChange: (l) => i(l) });
}
export {
  d as default
};
