import { jsx as n } from "react/jsx-runtime";
import { useStateTogether as s } from "@multisynq/react-together";
import { TabView as i } from "primereact/tabview";
function x({
  rtKey: e,
  ...t
}) {
  const [a, r] = s(e, 0);
  return /* @__PURE__ */ n(
    i,
    {
      ...t,
      activeIndex: a,
      onTabChange: (o) => r(o.index),
      pt: {
        panelContainer: { className: "bg-transparent" },
        root: {
          className: "border-gray-700 shadow-md px-4 rounded-xl"
        }
      }
    }
  );
}
export {
  x as default
};
