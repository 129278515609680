import { jsx as t, Fragment as n } from "react/jsx-runtime";
import { useStateTogether as s } from "@multisynq/react-together";
import { Checkbox as l } from "primereact/checkbox";
function u({
  rtKey: o,
  ...e
}) {
  const [r, a] = s(o, !1);
  return /* @__PURE__ */ t(n, { children: /* @__PURE__ */ t(
    l,
    {
      ...e,
      onChange: (c) => a(c.checked || !1),
      checked: r,
      className: `outline outline-1 outline-slate-400 rounded ${e.className}`
    }
  ) });
}
export {
  u as default
};
