import { jsx as n } from "react/jsx-runtime";
import { useStateTogether as u } from "@multisynq/react-together";
import { Knob as m } from "primereact/knob";
function i({ rtKey: e, ...o }) {
  const [t, r] = u(e, 0);
  return /* @__PURE__ */ n(m, { ...o, value: t || 0, onChange: (a) => r(a.value) });
}
export {
  i as default
};
