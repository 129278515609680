import { jsx as f } from "react/jsx-runtime";
import { CroquetRoot as h } from "@croquet/react";
import u from "../models/ReactTogetherModel.js";
import { getSessionNameFromUrl as g, getSessionPasswordFromUrl as v } from "../utils/urls.js";
function C({
  children: a,
  sessionParams: o,
  sessionIgnoresUrl: d,
  userId: t
}) {
  const { appId: c, apiKey: l } = o;
  let i;
  d || (i = {
    sessionUrl: window.location.origin + window.location.pathname
  });
  const r = new URL(window.location.href), m = g(r), w = v(r), p = o.model || u, n = m || o.name, s = w || o.password;
  let e = o.viewData;
  return t !== void 0 && (e === void 0 ? e = { userId: t } : e.userId = t), /* @__PURE__ */ f(
    h,
    {
      sessionParams: {
        model: p,
        name: n,
        password: s,
        appId: c,
        apiKey: l,
        options: i,
        viewData: e
      },
      deferSession: !n || !s,
      showChildrenWithoutSession: !0,
      children: a
    }
  );
}
export {
  C as default
};
