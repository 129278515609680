import { useCroquetContext as E } from "@croquet/react";
import { h as m } from "../object_hash-1efe2599.js";
import { useState as S, useEffect as C, useCallback as j } from "react";
import v from "./getNewValue.js";
import k from "./useMyId.js";
const g = Object.freeze({});
function U(e) {
  return Object.fromEntries(e.entries());
}
function B(e, T, A = {}) {
  const {
    resetOnDisconnect: d = !1,
    resetOnConnect: p = !1,
    keepValues: V = !1,
    overwriteSessionValue: w = !1
  } = A, [u] = S(T), { session: P, view: t, model: l } = E(), a = k(), [I, f] = S(() => {
    if (!t || !l || a === null)
      return {
        localValue: u,
        allValues: g,
        allValuesHash: null
      };
    const n = U(
      l.statePerUser.get(e) ?? /* @__PURE__ */ new Map([[a, u]])
    );
    return {
      localValue: n[a],
      allValues: n,
      allValuesHash: m(n)
    };
  });
  C(() => {
    if (t && l && a) {
      const n = l.statePerUserConfig.get(e);
      (!n || n.keepValues !== V) && t.publish(l.id, "configureStatePerUser", {
        rtKey: e,
        options: {
          // intentionally not passing other options to save bandwidth.
          // These values do not need to be synchronized
          // across users, and are only used locally to determine the behavior of the setter
          keepValues: V
        }
      });
    }
  }, [t, l, e, d, p, V, a]), C(() => {
    if (!P || !t || !l || a === null) {
      f((s) => ({
        localValue: d ? u : s.localValue,
        allValues: g,
        allValuesHash: null
      }));
      return;
    }
    (() => {
      f((s) => {
        const o = new Map(l.statePerUser.get(e)), i = s.localValue, h = o.get(a);
        let c;
        p ? c = u : i === void 0 ? c = h ?? u : h === void 0 ? c = i ?? u : c = w ? i : h, c !== h && (t.publish(l.id, "setStatePerUser", {
          rtKey: e,
          userId: a,
          value: c
        }), o.set(a, c));
        const b = U(o), H = m(b);
        return s.allValuesHash === H ? s : {
          localValue: c,
          allValues: b,
          allValuesHash: H
        };
      });
    })();
    const r = () => {
      f((s) => {
        const o = U(
          l.statePerUser.get(e) ?? /* @__PURE__ */ new Map()
        ), i = m(o);
        return s.allValuesHash === i ? s : {
          localValue: o[a],
          allValues: o,
          allValuesHash: i
        };
      });
    };
    return t.subscribe(
      e,
      { event: "updated", handling: "oncePerFrame" },
      r
    ), () => {
      t.unsubscribe(e, "updated", r);
    };
  }, [
    e,
    P,
    t,
    l,
    u,
    a,
    d,
    p,
    V,
    w
  ]);
  const L = j(
    (n) => {
      if (!t || !l || a === null)
        f((r) => {
          const s = v(r.localValue, n);
          return r.localValue === s ? r : {
            localValue: s,
            allValues: g,
            allValuesHash: null
          };
        });
      else {
        let s = l.statePerUser.get(e).get(a);
        s === void 0 && (console.warn(
          `[useStateTogetherWithPerUserValues:setter] prevLocalValue is undefined.Using initialValue: ${u}`
        ), s = u);
        const o = v(s, n);
        t.publish(l.id, "setStatePerUser", {
          rtKey: e,
          userId: a,
          value: o
        });
      }
    },
    [e, t, l, u, a]
  ), { localValue: M, allValues: O } = I;
  return [M, L, O];
}
export {
  B as default
};
