import { useJoinedViews as r, useModelRoot as s } from "@croquet/react";
import { uniqueNamesGenerator as n, adjectives as a, animals as i } from "unique-names-generator";
import m from "./useMyId.js";
const u = [];
function l() {
  r();
  const o = s(), t = m();
  return o ? Array.from(o.userIdCount.keys()).map((e) => ({
    userId: e,
    isYou: e === t,
    name: n({
      seed: e,
      dictionaries: [a, i],
      length: 2,
      separator: " ",
      style: "capital"
    })
  })) : u;
}
export {
  l as default
};
