import { useCroquetContext as b } from "@croquet/react";
import { useState as h, useEffect as i, useCallback as m } from "react";
import g from "./getNewValue.js";
function C(t, o, { resetOnDisconnect: r = !1 } = {}) {
  const { session: f, view: s, model: e } = b(), [l, n] = h(() => !s || !e ? o : e.state.has(t) ? e.state.get(t) : (s.publish(e.id, "setState", { rtKey: t, value: o }), o));
  i(() => {
    if (!f || !s || !e) {
      r && n(o);
      return;
    }
    const u = () => {
      n((a) => {
        if (!e.state.has(t))
          return s.publish(e.id, "setState", { rtKey: t, value: a }), a;
        const d = e.state.get(t);
        return a === d ? a : d;
      });
    };
    return s.subscribe(
      t,
      { event: "updated", handling: "oncePerFrame" },
      u
    ), u(), () => s.unsubscribe(t, "updated", u);
  }, [f, s, e, t, n, o, r]);
  const c = m(
    (u) => {
      if (e && s) {
        const a = e.state.get(t);
        s.publish(e.id, "setState", {
          rtKey: t,
          value: g(a, u)
        });
      } else
        n(u);
    },
    [n, e, s, t]
  );
  return [l, c];
}
export {
  C as default
};
