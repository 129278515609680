import { jsx as g } from "react/jsx-runtime";
import { useStateTogether as u } from "@multisynq/react-together";
import { ToggleButton as a } from "primereact/togglebutton";
function s({
  rtKey: e,
  ...t
}) {
  const [o, r] = u(e, !1);
  return /* @__PURE__ */ g(
    a,
    {
      ...t,
      checked: o,
      onChange: (n) => r(n.value)
    }
  );
}
export {
  s as default
};
