import { jsx as m } from "react/jsx-runtime";
import { useStateTogether as n } from "@multisynq/react-together";
import { Rate as u } from "antd";
function p({ rtKey: e, ...t }) {
  const [o, r] = n(e, 0);
  return /* @__PURE__ */ m(u, { ...t, value: o, onChange: (a) => r(a) });
}
export {
  p as default
};
