import { default as x } from "./useConnectedUsers.js";
import { default as c } from "./useCreateRandomSession.js";
import { default as S } from "./useFunctionTogether.js";
import { default as T } from "./useHoveringUsers.js";
import { useSessionParams as t, useIsJoined as s } from "@croquet/react";
import { useMemo as u } from "react";
import { default as I } from "./useLeaveSession.js";
import { default as v } from "./useMyId.js";
import { default as L } from "./useStateTogether.js";
import { default as P } from "./useStateTogetherWithPerUserValues.js";
import { getJoinUrl as a } from "../utils/urls.js";
function d() {
  const { name: e, password: o } = t(), r = n();
  return u(() => !r || !e || !o ? null : a(new URL(window.location.href), e, o).toString(), [e, o, r]);
}
const n = s;
export {
  x as useConnectedUsers,
  c as useCreateRandomSession,
  S as useFunctionTogether,
  T as useHoveringUsers,
  n as useIsTogether,
  d as useJoinUrl,
  I as useLeaveSession,
  v as useMyId,
  L as useStateTogether,
  P as useStateTogetherWithPerUserValues
};
