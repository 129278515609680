import { jsx as m } from "react/jsx-runtime";
import { useStateTogether as o } from "@multisynq/react-together";
import { SelectButton as s } from "primereact/selectbutton";
function p({
  rtKey: r,
  options: e,
  ...a
}) {
  const [l, u] = o(r, null);
  return /* @__PURE__ */ m(
    s,
    {
      ...a,
      onChange: (t) => u(t.value || !1),
      options: e,
      value: l || (e == null ? void 0 : e[0]),
      pt: {
        button: (t) => ({
          className: `border h-[15px] ${(t || { props: {} }).props.className}`
        })
      }
    }
  );
}
export {
  p as default
};
