import { jsx as c } from "react/jsx-runtime";
import { useStateTogether as h } from "@multisynq/react-together";
import { Switch as f } from "antd";
function s({
  rtKey: t,
  ...o
}) {
  const [e, r] = h(t, !1);
  return /* @__PURE__ */ c(
    f,
    {
      ...o,
      checked: e,
      onChange: () => r(!e)
    }
  );
}
export {
  s as default
};
