var h = Object.defineProperty;
var d = (o, s, e) => s in o ? h(o, s, { enumerable: !0, configurable: !0, writable: !0, value: e }) : o[s] = e;
var a = (o, s, e) => (d(o, typeof s != "symbol" ? s + "" : s, e), e);
import { ReactModel as p } from "@croquet/react";
class c extends p {
  constructor() {
    super(...arguments);
    a(this, "state");
    a(this, "statePerUser");
    a(this, "statePerUserConfig");
    a(this, "viewIdUserIdMapping");
    a(this, "userIdCount");
  }
  init(e) {
    super.init({ ...e, trackViews: !0 }), this.state = /* @__PURE__ */ new Map(), this.statePerUser = /* @__PURE__ */ new Map(), this.statePerUserConfig = /* @__PURE__ */ new Map(), this.viewIdUserIdMapping = /* @__PURE__ */ new Map(), this.userIdCount = /* @__PURE__ */ new Map(), this.subscribe(this.id, "setState", this.setState), this.subscribe(this.id, "setStatePerUser", this.setStatePerUser), this.subscribe(this.id, "configureStatePerUser", this.configureStatePerUser), this.subscribe(this.id, "functionTogether", this.functionTogether);
  }
  handleViewJoin(e, t) {
    var n;
    const i = ((n = t.viewData) == null ? void 0 : n.userId) ?? e;
    this.viewIdUserIdMapping.set(e, i);
    const r = this.userIdCount.get(i) ?? 0;
    this.userIdCount.set(i, r + 1);
  }
  handleViewExit(e) {
    const t = g(this, e), i = this.userIdCount.get(t);
    if (i === void 0) {
      console.warn(
        `No userIdCount found for userId ${t}. This is likely a bug. Please report it to the React Together team.`
      );
      return;
    }
    i <= 1 ? (this.statePerUser.forEach((r, n) => {
      const u = this.statePerUserConfig.get(n);
      u != null && u.keepValues || (r.delete(t), this.publish(n, "updated", {}));
    }), this.userIdCount.delete(t)) : this.userIdCount.set(t, i - 1), this.viewIdUserIdMapping.delete(e);
  }
  setState({ rtKey: e, value: t }) {
    t === void 0 ? this.state.delete(e) : this.state.set(e, t), this.publish(e, "updated", {});
  }
  setStatePerUser({ rtKey: e, userId: t, value: i }) {
    let r = this.statePerUser.get(e);
    r === void 0 && (r = /* @__PURE__ */ new Map(), this.statePerUser.set(e, r)), r.set(t, i), this.publish(e, "updated", {});
  }
  configureStatePerUser({ rtKey: e, options: t }) {
    const { keepValues: i } = t, r = this.statePerUserConfig.get(e) ?? {};
    i !== void 0 && (r.keepValues = i), this.statePerUserConfig.set(e, r);
  }
  functionTogether({ rtKey: e, args: t }) {
    this.publish(e, "call", t);
  }
}
c.register("ReactTogetherModel");
function g(o, s) {
  const e = o.viewIdUserIdMapping.get(s);
  return e === void 0 ? (console.warn(
    `No userId found for viewId ${s}. This is likely a bug. Please report it to the React Together team.`
  ), s) : e;
}
export {
  c as default,
  g as getUserId
};
