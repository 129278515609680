import { jsx as n } from "react/jsx-runtime";
import { useStateTogether as u } from "@multisynq/react-together";
import { Select as a } from "antd";
function c({
  rtKey: e,
  ...t
}) {
  const [o, r] = u(e, null);
  return /* @__PURE__ */ n(a, { ...t, value: o, onChange: (l) => r(l) });
}
export {
  c as default
};
