import { jsx as l } from "react/jsx-runtime";
import { useStateTogether as a } from "@multisynq/react-together";
import { MultiSelect as i } from "primereact/multiselect";
function s({
  rtKey: e,
  ...t
}) {
  const [o, r] = a(e, []);
  return /* @__PURE__ */ l(
    i,
    {
      ...t,
      value: o,
      onChange: (u) => r(u.value)
    }
  );
}
export {
  s as default
};
